import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.string.starts-with";
import "core-js/modules/es6.regexp.split";
import _createForOfIteratorHelper from "/var/jenkins_home/workspace/zippo-mdm-vue-prd/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es6.string.link";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import request from '@/utils/request';
import waves from '@/directive/waves'; // waves directive
import Pagination from '@/components/Pagination';
import { getNowString, getLocalTimestamp, deepClone } from '@/utils';
import GeneralEditForm from '@/components/GeneralEditForm';
import { generalQuery, generalDelete, generalBatchUpdate, generalValueLabelList, generalTree, generalDownload } from '@/api/general';
import { generateTitle } from '@/utils/i18n';
export default {
  name: 'Index',
  components: {
    Pagination: Pagination,
    GeneralEditForm: GeneralEditForm
  },
  directives: {
    waves: waves
  },
  filters: {},
  props: {
    tableName: {
      type: String,
      default: ''
    },
    url: {
      type: Object,
      default: null
    },
    rowKey: {
      type: String,
      default: 'c_id'
    },
    canSearch: {
      type: Boolean,
      default: true
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    addButton: {
      type: Object,
      default: null
    },
    canImport: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canMultiCheck: {
      type: Boolean,
      default: false
    },
    canBatchEdit: {
      type: Boolean,
      default: false
    },
    canDownload: {
      type: Boolean,
      default: true
    },
    canCollapse: {
      type: Boolean,
      default: true
    },
    moduleName: {
      type: String,
      default: ''
    },
    selfHandlerColumns: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    operation: {
      type: Array,
      default: null
    },
    onSuccess: {
      type: Function,
      default: null
    },
    cascaderCheckStrictly: {
      type: Boolean,
      default: true
    },
    getColumnLink: {
      type: Function,
      default: null
    }
  },
  data: function data() {
    return {
      tableKey: 0,
      tableColumns: [],
      queryColumns: [],
      batchColumns: [],
      batchUpdateForm: {},
      listData: [],
      listQuery: {
        page: 1,
        size: 10,
        language: this.$store.getters.language,
        param: {},
        extra: {}
      },
      selectedData: [],
      dialogBatchEditVisible: false,
      selectedDataInCurrentPage: [],
      selectedDataPage: 1,
      selectedDataLimit: 10,
      selectedBatchUpdateColumn: '',
      batchUpdateButtonDisabled: false,
      total: 0,
      listLoading: true,
      downloadLoading: false,
      queryLoading: false,
      showSearchArea: true,
      showCollapseButton: false,
      parentListQuery: {},
      currentTable: {}
    };
  },
  computed: {
    codes: function codes() {
      return this.$store.getters.codeDataDictionary.codes;
    }
  },
  watch: {
    currentTable: function currentTable(value) {
      // console.log('watch currentTable()')
      // this.setTableColumns()
      // this.getListData()
    },
    codes: function codes(value) {
      // this.setSelectOptions()
    }
  },
  created: function created() {
    // console.log(this.tableName+' created')
    // this.showCollapseButton = this.canCollapse
    if (this.url.query && Object.keys(this.url.query).length > 0) {
      this.parentListQuery = deepClone(this.url.query);
      this.listQuery = deepClone(this.url.query);
    }
    this.initCurrentTable();
    this.setTableColumns();
    this.checkCachedListQuery();
    this.getListData();
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    // console.log('beforeRouteLeave, to path='+to.path)
    next();
  },
  mounted: function mounted() {},
  methods: {
    initCurrentTable: function initCurrentTable() {
      var _this = this;
      var tdds = this.$store.getters.tableDataDictionary.tables;
      // console.log('currentTable()')
      if (tdds && tdds.length > 0) {
        this.currentTable = JSON.parse(JSON.stringify(tdds.find(function (value) {
          return value.name === _this.tableName;
        }).tableColumns));
      } else {
        this.currentTable = null;
        var randomNum = Math.round(Math.random() * 10000);
        this.$router.push('/?' + randomNum);
      }
    },
    getLink: function getLink(row, col) {
      if (this.getColumnLink) {
        var link = this.getColumnLink(col, row, this);
        if (!isNull(link)) {
          return link;
        }
      }
      if (!isNull(col.link)) {
        if (typeof col.link === 'function') {
          return col.link(row);
        }
        return col.link + row[this.rowKey];
      }
      return './edit/' + row[this.rowKey];
    },
    checkCachedListQuery: function checkCachedListQuery() {
      // 如果在本组件的父页面的beforeRouteLeave方法中，将查询条件保存至缓存
      // 那么，在本组件加载时可以从缓存中获取查询条件，以回显用户上次离开本页面时的查询条件及结果
      // 该缓存只使用一次，本组件获取后立即清除该缓存。F5刷新页面，将会获得一个默认页面
      var cachedListQuery = JSON.parse(sessionStorage.getItem(window.location.href + '_LIST_QUERY'));
      if (cachedListQuery && Object.keys(cachedListQuery).length > 0) {
        //console.log('checkCachedListQuery... cached list query is :')
        sessionStorage.removeItem(window.location.href + '_LIST_QUERY');
        this.listQuery = deepClone(cachedListQuery);
      }
      //console.log(this.listQuery)
      //设置已使用选项控件的options
      var _iterator = _createForOfIteratorHelper(this.currentTable.filter(function (c) {
          return c.isOption !== null && c.isOption === true;
        })),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          if (this.listQuery.param && this.listQuery.param[item.code] && this.listQuery.param[item.code].length > 0) {
            this.checkOptionItemSelectOptions(item);
            //对于cascaderUseSelect处理回显
            if (item.cascaderUseSelect) {
              this.getSelectOptions(null, item);
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      if (Object.keys(this.listQuery).length === 0) {
        this.listQuery = {
          page: 1,
          size: 10,
          language: this.$store.getters.language
        };
      }
    },
    getListQuery: function getListQuery() {
      return this.listQuery;
    },
    setTableColumns: function setTableColumns() {
      if (this.currentTable) {
        this.tableColumns = this.currentTable.filter(function (n) {
          return n.visible;
        });
        this.queryColumns = this.currentTable.filter(function (n) {
          return n.queryable;
        });
        this.batchColumns = this.currentTable.filter(function (n) {
          return n.batchEditable;
        });
        // console.log(this.queryColumns)
        this.setSelectOptions();
        if (this.canCollapse && this.queryColumns.length > 10) {
          this.showCollapseButton = true;
          //如果已有搜索条件，则默认显示搜索区域
          if (!isNull(this.listQuery.param) && Object.keys(this.listQuery.param).length > 0) {
            this.showSearchArea = true;
          } else {
            this.showSearchArea = false;
          }
        }
      } else {
        var randomNum = Math.round(Math.random() * 10000);
        this.$router.push('/?' + randomNum);
      }
    },
    setSelectOptions: function setSelectOptions() {
      var _this2 = this;
      var _iterator2 = _createForOfIteratorHelper(this.currentTable.filter(function (c) {
          return c.isOption !== null && c.isOption === true;
        })),
        _step2;
      try {
        var _loop = function _loop() {
          var item = _step2.value;
          //console.log(item.code)
          var codeDD = _this2.codes.filter(function (n) {
            return n.propId === item.propId;
          });
          if ((item.type === 'treeSingleSelect' || item.type === 'treeMultiSelect') && item.treeUrl && item.treeUrl.indexOf('/') >= 0) {} else if (item.optionsUrl && item.optionsUrl.length > 0 && item.optionsUrl.indexOf('/') >= 0) {} else if (item.options == null || item.options.length == 0) {
            if (codeDD && codeDD[0] && codeDD[0].data && codeDD[0].data.length > 0) {
              // console.log(item.code)
              // console.log(codeDD[0].data)
              if ((item.type === 'singleSelect' || item.type === 'multiSelect') && item.hasParent === true) {
                var maxLimit = 100;
                var count = 0;
                var _iterator3 = _createForOfIteratorHelper(codeDD[0].data),
                  _step3;
                try {
                  for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
                    var o = _step3.value;
                    count = count + _this2.getOptionCount(o);
                  }
                } catch (err) {
                  _iterator3.e(err);
                } finally {
                  _iterator3.f();
                }
                if (count > maxLimit) {
                  item['cascaderUseSelect'] = true;
                  _this2.getSelectOptions(null, item);
                } else {
                  item['cascaderUseSelect'] = false;
                  item.options = codeDD[0].data;
                }
                // console.log(item.options)
              } else {
                item.options = codeDD[0].data;
              }
            }
          }
        };
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    },
    convertSelectValueToCasader: function convertSelectValueToCasader(__listQuery, item) {
      if (item.cascaderUseSelect && __listQuery.param && __listQuery.param[item.code]) {
        var value = [];
        var _iterator4 = _createForOfIteratorHelper(__listQuery.param[item.code]),
          _step4;
        try {
          for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
            var s = _step4.value;
            var sa = s.split('_');
            value.push([parseInt(sa[0]), parseInt(sa[1])]);
          }
          //console.log(value)
        } catch (err) {
          _iterator4.e(err);
        } finally {
          _iterator4.f();
        }
        __listQuery.param[item.code] = value;
      }
    },
    getListData: function getListData() {
      var _this3 = this;
      // console.log('getListData')
      this.listLoading = true;
      if (this.listQuery.language == undefined || this.listQuery.language == null) {
        this.listQuery['language'] = this.$store.getters.language == null ? 1 : this.$store.getters.language;
      }
      var __listQuery = deepClone(this.listQuery);
      var _iterator5 = _createForOfIteratorHelper(this.queryColumns),
        _step5;
      try {
        for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
          var item = _step5.value;
          this.convertSelectValueToCasader(__listQuery, item);
        }
        // console.log(this.url.query)
        // console.log(this.listQuery)
      } catch (err) {
        _iterator5.e(err);
      } finally {
        _iterator5.f();
      }
      return new Promise(function (resolve, reject) {
        generalQuery(_this3.url.getUrl, __listQuery).then(function (response) {
          // console.log(response)
          if (response.data.code !== '0') {
            _this3.$notify({
              title: _this3.$t('notification.error.title'),
              message: response.data.message,
              type: 'error',
              duration: 3000
            });
          }
          try {
            if (typeof _this3.onSuccess == 'function') {
              _this3.onSuccess(response, _this3);
            }
          } catch (e) {}
          _this3.listData = response.data.data;
          _this3.total = response.data.total;
          _this3.listLoading = false;
          _this3.$refs.multipleTable.clearSelection();
          resolve(response);
        }).catch(function (error) {
          _this3.listLoading = false;
          reject(error);
        });
      });
    },
    handleQuery: function handleQuery() {
      this.listQuery.page = 1;
      this.listQuery.language = this.$store.getters.language;
      this.getListData();
    },
    resetQuery: function resetQuery() {
      this.listQuery = {
        page: 1,
        size: 10,
        language: this.$store.getters.language,
        param: {}
      };
      if (this.url.query && Object.keys(this.url.query).length > 0) {
        this.parentListQuery = deepClone(this.url.query);
        this.listQuery = deepClone(this.url.query);
      }
      this.handleQuery();
    },
    handleDelete: function handleDelete(row) {
      var _this4 = this;
      this.$confirm(this.$t('messageBox.confirm.body'), this.$t('messageBox.confirm.title'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(function () {
        _this4.listLoading = true;
        var id = row[_this4.rowKey];
        return new Promise(function (resolve, reject) {
          generalDelete(_this4.url.getUrl, id).then(function (response) {
            //console.log(response)
            if (response.data.code === '0') {
              _this4.$notify({
                title: _this4.$t('notification.success.title'),
                message: _this4.$t('notification.success.body'),
                type: 'success',
                duration: 3000
              });
            } else {
              _this4.$notify({
                title: _this4.$t('notification.error.title'),
                message: response.data.message,
                type: 'error',
                duration: 3000
              });
            }
            _this4.getListData();
            _this4.listLoading = false;
            resolve(response);
          }).catch(function (error) {
            _this4.listLoading = false;
            reject(error);
          });
        });
      });
    },
    sortChange: function sortChange(data) {
      // console.log(data)
      var prop = data.prop,
        order = data.order;
      if (order === 'ascending') {
        this.listQuery.sort = '+' + prop;
      } else {
        this.listQuery.sort = '-' + prop;
      }
      this.handleQuery();
    },
    selectionChange: function selectionChange(val) {
      // console.log(val)
      this.selectedData = val;
    },
    getSelectedDataInCurrentPage: function getSelectedDataInCurrentPage() {
      this.selectedDataInCurrentPage = this.selectedData.slice((this.selectedDataPage - 1) * this.selectedDataLimit, this.selectedDataPage * this.selectedDataLimit);
      // console.log(this.selectedDataInCurrentPage)
    },
    openBatchEditDialog: function openBatchEditDialog() {
      if (this.selectedData.length > 0) {
        this.dialogBatchEditVisible = true;
        this.batchUpdateButtonDisabled = false;
        this.selectedDataPage = 1;
        this.selectedDataLimit = 10;
        this.getSelectedDataInCurrentPage();
        this.setSelectOptions();
        //console.log(this.batchColumns)
        var _iterator6 = _createForOfIteratorHelper(this.batchColumns.filter(function (c) {
            return c.isOption !== null && c.isOption === true;
          })),
          _step6;
        try {
          for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
            var item = _step6.value;
            if (item.optionsUrl && item.optionsUrl.length > 0 && item.optionsUrl.indexOf('/') >= 0) {
              // console.log(item)
              this.getOptionItemSelectOptionsValueLabelList(item);
            }
          }
        } catch (err) {
          _iterator6.e(err);
        } finally {
          _iterator6.f();
        }
      } else {
        this.$message({
          message: this.$t('table.batchEdit.noSelectedData'),
          type: 'warning',
          duration: 5000,
          offset: 300
        });
      }
    },
    closeBatchEditDialog: function closeBatchEditDialog() {
      this.dialogBatchEditVisible = false;
    },
    handleDialogSelectedDataLimitChange: function handleDialogSelectedDataLimitChange(val) {
      this.selectedDataLimit = val;
      this.getSelectedDataInCurrentPage();
    },
    handleDialogSelectedDataPageChange: function handleDialogSelectedDataPageChange(val) {
      this.selectedDataPage = val;
      this.getSelectedDataInCurrentPage();
    },
    handleBatchUpdate: function handleBatchUpdate() {
      var _this5 = this;
      var submitBatchUpdateForm = deepClone(this.batchUpdateForm);
      //console.log(submitBatchUpdateForm)
      for (var key in submitBatchUpdateForm) {
        if (submitBatchUpdateForm[key] == '') {
          delete submitBatchUpdateForm[key];
        }
      }
      //console.log(submitBatchUpdateForm)
      this.$confirm(this.$t('table.batchEdit.dialogSecondConfirm'), this.$t('table.batchEdit.dialogMessageTitle'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(function () {
        var ids = '';
        var _iterator7 = _createForOfIteratorHelper(_this5.selectedData),
          _step7;
        try {
          for (_iterator7.s(); !(_step7 = _iterator7.n()).done;) {
            var item = _step7.value;
            ids = ids + ',' + item[_this5.rowKey];
          }
        } catch (err) {
          _iterator7.e(err);
        } finally {
          _iterator7.f();
        }
        if (ids.length > 0 && ids.substring(0, 1) === ',') {
          ids = ids.substring(1, ids.length);
        }
        if (Object.keys(submitBatchUpdateForm).length === 0) {
          _this5.$message({
            message: _this5.$t('table.batchEdit.dialogNoInputWarn'),
            type: 'warning',
            duration: 10000,
            offset: 300
          });
          return false;
        } else {
          _this5.listLoading = true;
          _this5.dialogBatchEditVisible = false;
          _this5.batchUpdateButtonDisabled = true;
          return new Promise(function (resolve, reject) {
            generalBatchUpdate(_this5.url.getUrl, ids, submitBatchUpdateForm).then(function (response) {
              //console.log(response)
              if (response.data.code === '0') {
                _this5.$notify({
                  title: _this5.$t('table.batchEdit.successMessageTitle'),
                  message: _this5.$t('table.batchEdit.successMessageBody'),
                  type: 'success',
                  duration: 3000
                });
                _this5.$refs.multipleTable.clearSelection();
                _this5.getListData();
              } else {
                _this5.$notify({
                  title: _this5.$t('notification.error.title'),
                  message: response.data.message,
                  type: 'error',
                  duration: 3000
                });
              }
              _this5.listLoading = false;
              resolve(response);
            }).catch(function (error) {
              _this5.listLoading = false;
              reject(error);
            });
          });
        }
      }).catch(function () {});
    },
    handleAdd: function handleAdd() {
      var addButton = this.addButton;
      if (addButton == null || !addButton.url) {
        this.$router.push('./add');
      } else {
        this.$router.push(addButton.url);
      }
    },
    handleImport: function handleImport() {
      // this.dialogImportFileVisible = true;
      this.$router.push('./import-data?module_name=' + this.moduleName);
    },
    handleDownload: function handleDownload() {
      var _this6 = this;
      // console.log('handleDownload')
      if (this.__listLoading) {
        return;
      }
      this.__listLoading = true;
      var __this = this;
      setTimeout(function () {
        __this.__listLoading = false;
      }, 3000);
      this.__listLoading = true;
      this.listLoading = true;
      var tHeader = [];
      var _iterator8 = _createForOfIteratorHelper(this.tableColumns),
        _step8;
      try {
        for (_iterator8.s(); !(_step8 = _iterator8.n()).done;) {
          var col = _step8.value;
          tHeader.push(col.label);
        }
        // console.log(tHeader)
      } catch (err) {
        _iterator8.e(err);
      } finally {
        _iterator8.f();
      }
      this.listQuery.param.header = tHeader;
      if (isNull(this.listQuery.extra)) {
        this.listQuery.extra = {};
      }
      this.listQuery.extra.tableColumns = this.tableColumns;
      var __file_name = this.$t('route.' + this.$route.meta.title) + this.EXCEL_SUFFIX;
      this.listQuery.extra.file_name = __file_name;
      var __listQuery = deepClone(this.listQuery);
      var _iterator9 = _createForOfIteratorHelper(this.queryColumns),
        _step9;
      try {
        for (_iterator9.s(); !(_step9 = _iterator9.n()).done;) {
          var item = _step9.value;
          this.convertSelectValueToCasader(__listQuery, item);
        }
      } catch (err) {
        _iterator9.e(err);
      } finally {
        _iterator9.f();
      }
      request({
        url: this.url.getUrl + '/download-excel',
        method: 'post',
        headers: {
          'accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        data: __listQuery,
        callBack: function callBack() {
          return true;
        },
        responseType: 'arraybuffer'
      }).then(function (response) {
        // console.log(response)
        var content = response.data;
        var blob = new Blob([content], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var fileName = __file_name;
        var __this = _this6;
        if (response.status === 200) {
          var headers = response.headers;
          if (headers['content-type'].startsWith('application/vnd')) {
            if ('download' in document.createElement('a')) {
              var elink = document.createElement('a');
              elink.download = fileName;
              elink.style.display = 'none';
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          } else {
            ab2string(response.data, function (data) {
              data = JSON.parse(data);
              console.log(data);
              if (data.code != '0') {
                if (data.code == '408') {
                  var __key = __this.$t(data.data.key);
                  var __value = data.data.value;
                  var __content = __key.format(__value);
                  __this.$confirm(__content, __this.$t('exportFile.confirm.title'), {
                    confirmButtonText: __this.$t('exportFile.confirm.confirmButton'),
                    cancelButtonText: __this.$t('exportFile.confirm.cancelButton'),
                    showCancelButton: true,
                    type: 'warning'
                  }).then(function () {}).catch(function () {
                    __this.$router.push('/sys/export-file/list');
                  });
                } else {
                  __this.$message({
                    message: data.message,
                    type: 'error'
                  });
                }
                return;
              } else {
                __this.$message({
                  message: data.message ? data.message : __this.$t('exportFile.exportSuccess'),
                  type: 'success'
                });
              }
            });
          }
        }
        _this6.listLoading = false;
      }).catch(function (error) {
        _this6.listLoading = false;
      });
    },
    handleCollapse: function handleCollapse() {
      this.showSearchArea = false;
    },
    handleExpand: function handleExpand() {
      this.showSearchArea = true;
    },
    getSortClass: function getSortClass(key) {
      var sort = this.listQuery.sort;
      return sort === "+".concat(key) ? 'ascending' : sort === "-".concat(key) ? 'descending' : '';
    },
    checkOptionItemSelectOptions: function checkOptionItemSelectOptions(item) {
      // console.log('checkOptionItemSelectOptions')
      // console.log(item.code)
      // 对于tree类型的options，在列表搜索中和批量更新中的格式不同，所以每次都刷新
      if ((item.type === 'treeSingleSelect' || item.type === 'treeMultiSelect') && item.treeUrl && item.treeUrl.indexOf('/') >= 0) {
        this.getOptionItemSelectOptionsTree(item);
      } else if (item.optionsUrl && item.optionsUrl.length > 0 && item.optionsUrl.indexOf('/') >= 0) {
        if (!item.options || item.options.length == 0) {
          this.getOptionItemSelectOptionsValueLabelList(item);
        }
      }
    },
    getOptionCount: function getOptionCount(options) {
      if (!options.children || options.children.length == 0) {
        return 1;
      } else {
        var count = 0;
        var _iterator10 = _createForOfIteratorHelper(options.children),
          _step10;
        try {
          for (_iterator10.s(); !(_step10 = _iterator10.n()).done;) {
            var c = _step10.value;
            count = count + this.getOptionCount(c);
          }
        } catch (err) {
          _iterator10.e(err);
        } finally {
          _iterator10.f();
        }
        return count;
      }
    },
    getSelectOptions: function getSelectOptions(query, item) {
      var maxLimit = 100;
      var count = 0;
      var codeDD = this.codes.filter(function (n) {
        return n.propId === item.propId;
      });
      var itemOptionsClone = deepClone(codeDD[0].data);
      var num = 0;
      var options = [];
      var _iterator11 = _createForOfIteratorHelper(itemOptionsClone),
        _step11;
      try {
        for (_iterator11.s(); !(_step11 = _iterator11.n()).done;) {
          var x = _step11.value;
          var _iterator12 = _createForOfIteratorHelper(x.children),
            _step12;
          try {
            for (_iterator12.s(); !(_step12 = _iterator12.n()).done;) {
              var y = _step12.value;
              //已选中的在最上面回显
              if (this.listQuery.param && this.listQuery.param[item.code] && this.listQuery.param[item.code].indexOf(x.value + '_' + y.value) >= 0) {
                count++;
                options.unshift({
                  value: x.value + '_' + y.value,
                  label: x.label + ' / ' + y.label
                });
                num++;
              } else if (query == null || x.label.toLowerCase().indexOf(query.toLowerCase()) >= 0 || y.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
                count++;
                if (num < maxLimit) {
                  options.push({
                    value: x.value + '_' + y.value,
                    label: x.label + ' / ' + y.label
                  });
                  num++;
                }
              }
            }
          } catch (err) {
            _iterator12.e(err);
          } finally {
            _iterator12.f();
          }
        }
        // console.log("count="+count+", num="+num)
        //console.log(options)
      } catch (err) {
        _iterator11.e(err);
      } finally {
        _iterator11.f();
      }
      if (num >= maxLimit) {
        options.unshift({
          value: '$$$$',
          label: '以下只显示了前' + num + '个，还有' + (count - num) + '个选项未显示，请通过搜索缩小范围',
          disabled: true
        });
      }
      item.options = options;
      //console.log(options)
    },
    getOptionItemSelectOptionsTree: function getOptionItemSelectOptionsTree(item) {
      var _this7 = this;
      // console.log(item.code+' getOptionItemSelectOptionsTree')
      // console.log(item.treeUrl)
      this.queryLoading = true;
      return new Promise(function (resolve, reject) {
        generalTree(item.treeUrl).then(function (response) {
          item.options = response.data.data;
          setTimeout(function () {
            _this7.queryLoading = false;
          }, 1.5 * 1000);
          if (response.data.code !== '0') {
            _this7.$notify({
              title: _this7.$t('notification.error.title'),
              message: response.data.message,
              type: 'error',
              duration: 3000
            });
          }
          _this7.queryLoading = false;
          //console.log(response.data)
          resolve(response);
        }).catch(function (error) {
          console.log(error);
          _this7.queryLoading = false;
          reject(error);
        });
      });
    },
    getOptionItemSelectOptionsValueLabelList: function getOptionItemSelectOptionsValueLabelList(item) {
      var _this8 = this;
      // console.log(item)
      // console.log(item.code + ' getOptionItemSelectOptionsValueLabelList')
      // console.log(item.optionsUrl)
      this.queryLoading = true;
      return new Promise(function (resolve, reject) {
        generalValueLabelList(item.optionsUrl).then(function (response) {
          item.options = response.data.data;
          // console.log(item.options)
          setTimeout(function () {
            _this8.queryLoading = false;
          }, 1.5 * 1000);
          if (response.data.code !== '0') {
            _this8.$notify({
              title: _this8.$t('notification.error.title'),
              message: response.data.message,
              type: 'error',
              duration: 3000
            });
          }
          _this8.queryLoading = false;
          //console.log(response.data)
          resolve(response);
        }).catch(function (error) {
          console.log(error);
          _this8.queryLoading = false;
          reject(error);
        });
      });
    },
    getLocalTime: function getLocalTime(timestamp) {
      return getLocalTimestamp(timestamp);
    },
    remoteMethod: function remoteMethod(query, item) {
      this.getSelectOptions(query, item);
    }
  }
};